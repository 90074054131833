import axios from 'axios';

import { GOOGLE_MAPS_KEY } from './config';

const BASE_URL_CEP = 'https://viacep.com.br/ws/';

const BASE_URL_CEP_LAT_LNG = 'https://maps.googleapis.com/maps/api/geocode/json?address=';

export const filterCep = (id) => {
    const url = `${BASE_URL_CEP}${id}/json/`

    let json = axios.get(url)
      return json
}

export const latlng = (cep) => {
    const url = `${BASE_URL_CEP_LAT_LNG}${cep}&key=${GOOGLE_MAPS_KEY}`

    let json = axios.get(url)
      return json
}

export const isSuper = true;

// https://maps.googleapis.com/maps/api/geocode/json?address=SEU_CEP&key=SUA_API_KEY

export default{    
    filterCep,
    latlng,
    isSuper
}